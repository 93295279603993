import React, { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

interface I extends TextProps {
  children: any;
}

export const ProductOrderedDetailsText: FC<I> = (props) => {
  const { children, ...rest } = props;
  return (
    <Text color="blackAlpha.400" textStyle="label" lineHeight="20px" w="100%" {...rest}>
      {children}
    </Text>
  );
};
