import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useLoyalty } from '@olo-web/domain/customer/hooks/useLoyalty';
import { useToast } from '@olo-web/utils/common/hooks';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

type RemoveOrderItemProps = {
  merchantId?: string;
  orderId?: string;
  guestId?: string;
  itemId: string;
};

export const removeOrderItem = async ({
  merchantId,
  orderId,
  itemId,
}: RemoveOrderItemProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/items/${itemId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useRemoveOrderItem = (
  options?: UseMutationOptions<IOrder, AxiosError, RemoveOrderItemProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const { sendEvent } = useSendEvent();
  const { data: loyalty } = useLoyalty();
  const { notify, toast } = useToast();
  const { data: order } = useOrder();
  const underMinAmountToastId = 'under-min-amount';
  const currentDiscountData =
    loyalty?.deals[order?.discounts[0]?.spotOnRewardId] ||
    loyalty?.rewards[order?.discounts[0]?.spotOnRewardId];

  return useMutation(removeOrderItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (Number(data?.itemSubtotal) < Number(currentDiscountData?.minPurchaseAmount)) {
        if (!toast.isActive(underMinAmountToastId)) {
          notify({
            id: underMinAmountToastId,
            description: `The ${
              order?.discounts[0]?.discountName ? order?.discounts[0]?.discountName : ''
            } promotion has been removed because your bag is no longer eligible.`,
            status: 'info',
            variant: 'left-accent',
            position: 'top',
            isClosable: true,
          });
        }
      }
      const { itemId } = variables;
      updateOrderInCache(data);
      sendEvent(EAnalyticsEventNames.REMOVE_FROM_CART, {
        googleAnalytics: { itemId },
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
