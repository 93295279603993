import React, { FC, memo, useMemo } from 'react';
import { Stack, StackProps } from '@chakra-ui/react';
import { ProductOrderedDetailsText } from './common';
import { createModifiersString } from './common/functions';
import { v4 as uuidv4 } from 'uuid';

interface I extends StackProps {
  modifiers: IMenuItemModifierGroup[];
}

type TOption = {
  option?: IMenuItemModifier;
  truncatedString?: string;
};

const Option: FC<TOption> = ({ option, truncatedString }) => {
  const text = useMemo(
    () => truncatedString || createModifiersString([option]),
    [option, truncatedString]
  );

  return (
    <ProductOrderedDetailsText
      noOfLines={truncatedString ? 1 : undefined}
      data-testid="ordered-item-modifier"
    >
      {text}
    </ProductOrderedDetailsText>
  );
};

const ProductOrderedModifiersInfoComponent: FC<I> = (props) => {
  const { modifiers, isTruncated, ...rest } = props;
  const options = useMemo(() => {
    return (modifiers as IMenuItemModifierGroup[]).reduce(
      (a, c) => [...a, ...(c.options || [])],
      []
    );
  }, [modifiers]);

  const truncatedString = createModifiersString([options[0]]).trim();

  return (
    <Stack spacing={0} mt={1} align="flex-start" justify="center" {...rest}>
      {isTruncated ? (
        <Option
          truncatedString={truncatedString.length <= 36 ? `${truncatedString}...` : truncatedString}
        />
      ) : (
        options.map((option) => <Option key={uuidv4()} option={option} />)
      )}
    </Stack>
  );
};

export const ProductOrderedModifiersInfo = memo(ProductOrderedModifiersInfoComponent);
