import { createModifiersString } from './createModifiersString';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';

export const createSubItemString = (item: IMenuItem, addPipe?: boolean) => {
  let text = `${addPipe ? ' | ' : ''}${item?.name}`;

  if (Number(item?.price)) {
    text += ` (+ ${formatPrice(item?.price)})`;
  }

  if (item?.modifiers?.length) {
    const options = item?.modifiers?.reduce((a, c) => [...a, ...(c?.options || [])], []);
    text += ` | ${createModifiersString(options)}`;
  }

  if (item?.subItems?.length) {
    const items = item?.subItems.reduce((a, c) => [...a, ...(c.items || [])], []);
    for (const itm of items) {
      text += createSubItemString(itm, true);
    }
  }

  return text;
};
