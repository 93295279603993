import React, { memo, useCallback, FC } from 'react';
import { Flex, Button, ButtonGroup } from '@chakra-ui/react';
import { EModalTypes } from '@olo-web/types/enums';
import { useModalDispatch } from '@olo-web/client-state';
import { useRemoveOrderItem } from '@olo-web/domain/orders/mutations/useRemoveOrderItem';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useBagBar } from '@olo-web/components/templates/Bag/hooks';

const buttonProps = {
  variant: 'tertiary',
  colorScheme: 'primary',
  color: 'primary.350',
  p: 0,
  minWidth: 0,
  fontWeight: '400',
  textDecor: 'underline',
};

type T = {
  orderItem: IOrderItem;
  showRemoveItemMessage: () => void;
};

const ProductActionButtonsComponent: FC<T> = ({ orderItem, showRemoveItemMessage }) => {
  const modalDispatch = useModalDispatch();
  const { handleClose: closeBag, handleOpen: openBag } = useBagBar();
  const { mutate: removeOrderItem, isLoading: isRemoving } = useRemoveOrderItem();
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();

  const handleEditClick = useCallback(
    (item: IOrderItem) => () => {
      closeBag();
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.ITEM_DETAILS,
          modalContext: { item, isEdit: true, onClose: openBag },
        },
      });
    },
    [modalDispatch, closeBag, openBag]
  );

  const handleRemoveClick = useCallback(
    (itemId: string) => () => {
      removeOrderItem({
        itemId,
        merchantId: merchant?.merchantId,
        orderId: order?.id,
      });
      showRemoveItemMessage();
    },
    [removeOrderItem, merchant?.merchantId, order?.id, showRemoveItemMessage]
  );

  return (
    <Flex width="100%">
      <ButtonGroup spacing={6}>
        <Button
          {...buttonProps}
          onClick={handleEditClick(orderItem)}
          data-testid="edit-item-button"
          h={7}
          aria-label={`Edit ${orderItem.name}`}
        >
          Edit
        </Button>
        <Button
          {...buttonProps}
          onClick={handleRemoveClick(orderItem?.id)}
          isLoading={isRemoving}
          loadingText="Removing..."
          data-testid="remove-item-button"
          h={7}
          aria-label={`Remove ${orderItem.name}`}
        >
          Remove
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export const ProductActionButtons = memo(ProductActionButtonsComponent);
