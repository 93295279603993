import React, { useRef, useState } from 'react';
import {
  Icon,
  Grid,
  GridItem,
  Text,
  GridProps,
  VisuallyHidden,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { X } from 'react-feather';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { ProductOrderedItemDetails } from '../ProductOrderedItemDetails';
import { ProductActionButtons } from '@atoms/ProductDetails/ProductActionButtons.ui';
import { ProductOrderedDetailsText } from '../common';
import { AnimateHeight } from '@olo-web/components/atoms/AnimateHeight';
import { gridTemplate } from '../ProductOrderedInfo.ui';
import { IFeatureFlags } from '@olo-web/types/featureFlags.interface';

export const OrderItems = ({
  items,
  showActionButtons,
  isMultiGuest,
  containerProps,
}: {
  items?: IOrderItem[];
  flags?: IFeatureFlags;
  showActionButtons: boolean;
  isMultiGuest?: boolean;
  containerProps?: GridProps;
}) => {
  const itemRemovedMessageRef = useRef<HTMLElement>(null);
  const [itemRemovedMessage, setItemRemovedMessage] = useState(false);

  const showRemoveItemMessage = () => {
    setItemRemovedMessage(true);
    itemRemovedMessageRef?.current?.focus();
  };
  return (
    <>
      <VisuallyHidden
        ref={itemRemovedMessageRef}
        tabIndex={0}
        role="alert"
        aria-live="polite"
        onBlur={() => setItemRemovedMessage(false)}
        display={itemRemovedMessage ? 'block' : 'none'}
      >
        Item is removed
      </VisuallyHidden>
      <UnorderedList
        role={items?.length === 1 ? 'none' : 'list'}
        marginInlineStart={0}
        width="100%"
      >
        {items?.map((orderItem, i) => {
          const { quantity, name, itemSummaryDisplayAmount, id, notes } = orderItem;
          return (
            <ListItem
              role={items?.length === 1 ? 'none' : 'listitem'}
              listStyleType="none"
              key={id}
            >
              <AnimateHeight key={id} transition={{ duration: 0.3 }}>
                <Grid
                  w={{ base: '100%' }}
                  {...(isMultiGuest && { m: '0 2px' })}
                  templateColumns={gridTemplate}
                  data-testid="ordered-item-in-bag"
                  {...containerProps}
                  {...(items.length - 1 !== i
                    ? { mb: 4 }
                    : items.length - 1 === i && !isMultiGuest
                    ? { mb: 6 }
                    : { mb: 0 })}
                >
                  <GridItem
                    colSpan={{ base: 8, xs: 12, sm: 16, md: 8 }}
                    mb={1}
                    role="text"
                    tabIndex={0}
                    w="100%"
                    justifyContent={'space-between'}
                  >
                    <Grid key={id} w="100%" templateColumns="32px 1fr 1fr 1fr 1fr 1fr 1fr 1fr">
                      <GridItem colSpan={1} w={8}>
                        <Text textStyle="p" data-testid="ordered-item-quantity">
                          {quantity}
                          <Icon as={X} h={3} w={3} />
                        </Text>
                      </GridItem>
                      <GridItem colSpan={{ base: 6 }} display="flex" justifyContent="flex-start">
                        <Text textStyle="pBold" data-testid="ordered-item-name">
                          {name}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={1} display="flex" justifyContent="flex-end">
                        <Text textStyle="p" data-testid="ordered-item-price">
                          {formatPrice(itemSummaryDisplayAmount)}
                        </Text>
                      </GridItem>
                    </Grid>
                  </GridItem>
                  <GridItem colSpan={{ base: 8, xs: 12, sm: 16, md: 8 }}>
                    <Grid key={id} w="100%" templateColumns="32px 1fr 1fr 1fr 1fr 1fr 1fr 1fr">
                      <GridItem w={8} colSpan={1} />
                      <GridItem colSpan={{ base: 7, xs: 11, sm: 15, md: 7 }}>
                        <ProductOrderedItemDetails orderItem={orderItem} />
                        {!!notes && (
                          <ProductOrderedDetailsText lineHeight="1.4" mt={2} noOfLines={3}>
                            <i>{notes}</i>
                          </ProductOrderedDetailsText>
                        )}
                        {showActionButtons && (
                          <ProductActionButtons
                            orderItem={orderItem}
                            showRemoveItemMessage={showRemoveItemMessage}
                          />
                        )}
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>
              </AnimateHeight>
            </ListItem>
          );
        })}
      </UnorderedList>
    </>
  );
};
