import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';

export const createModifiersString = (options?: IMenuItemModifier[] | ISubModifierOption[]) => {
  return (options as any).reduce((a, c, i) => {
    let text = i > 0 ? ' | ' : ' ';
    text += c?.name;
    if (Number(c?.price)) text += ` (+ ${formatPrice(c?.price)})`;
    return (a + text).trim();
  }, '');
};
