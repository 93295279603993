import React from 'react';
import { VStack, Text, HStack, Button } from '@chakra-ui/react';
import { InfoIcon } from '@olo-web/assets/icons/InfoIcon.ui';
import { OrderItems } from './OrderItems';
import { useToast } from '@olo-web/utils/common/hooks';
import { filterSentOrUnsentItems } from '@olo-web/utils/common/functions';
import { IFeatureFlags } from '@olo-web/types/featureFlags.interface';
const GuestItems = ({
  name,
  items,
  flags,
  showActionButtons,
  rightTitleAdornment,
  isLast,
  dataTestId,
}: {
  name: string;
  items?: IOrderItem[];
  flags?: IFeatureFlags;
  showActionButtons: boolean;
  rightTitleAdornment?: JSX.Element | JSX.Element[];
  isLast?: boolean;
  dataTestId?: string;
}) => {
  return (
    <VStack
      mb={`${isLast ? '24px' : '20px'} !important`}
      mt="0 !important"
      alignItems="flex-start"
      w="100%"
      data-testid={dataTestId}
    >
      <HStack spacing={0}>
        <Text
          textStyle="p"
          textTransform="uppercase"
          w="100%"
          color="base.300"
          fontWeight="500"
          left="0"
        >
          {name}
        </Text>
        {rightTitleAdornment}
      </HStack>
      <VStack
        position="relative"
        pl={4}
        pt={1}
        _before={{
          content: `""`,
          width: '4px',
          height: '100%',
          backgroundColor: '#77819C',
          borderRadius: '100px',
          opacity: '0.2',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        w="100%"
      >
        <OrderItems
          isMultiGuest
          containerProps={{ mt: '-5px' }}
          items={items}
          flags={flags}
          showActionButtons={showActionButtons}
        />
      </VStack>
    </VStack>
  );
};

export const MultiGuestItems = ({
  currentGuest,
  guests,
  flags,
  showActionButtons,
  isUnsentItemsOnly,
  isSentItemsOnly,
  tableItems,
}: {
  currentGuest?: IGuest;
  guests: IGuest[];
  flags: IFeatureFlags | undefined;
  showActionButtons: boolean;
  isUnsentItemsOnly?: boolean;
  isSentItemsOnly?: boolean;
  tableItems?: IOrderItem[];
}) => {
  const { notify } = useToast();
  return (
    <React.Fragment>
      {guests
        .sort((a, b) => (a.id === currentGuest?.id ? -1 : b.id === currentGuest?.id ? 1 : 0))
        .map((guest, i) => {
          const itemsToUse = filterSentOrUnsentItems(
            guest?.items,
            isUnsentItemsOnly,
            isSentItemsOnly
          );
          if (itemsToUse.length === 0 && guest?.items?.length === 0) {
            return null;
          }
          return (
            <GuestItems
              key={guest?.id}
              flags={flags}
              items={guest?.items}
              name={guest?.name}
              showActionButtons={showActionButtons}
              isLast={guests?.length - 1 === i}
              dataTestId={`guests-items-${i}`}
            />
          );
        })}
      {!!tableItems?.length && (
        <GuestItems
          rightTitleAdornment={
            <Button
              h="44px"
              w="44px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="transparent"
              _hover={{ background: 'transparent' }}
              onClick={() => {
                notify({
                  description: 'These items were added to the order by your server.',
                  variant: 'left-accent',
                });
              }}
            >
              <InfoIcon color="primary.300" />
            </Button>
          }
          items={tableItems}
          name="Unassigned"
          flags={flags}
          showActionButtons={false}
          dataTestId={`guests-unassigned-items`}
        />
      )}
    </React.Fragment>
  );
};
