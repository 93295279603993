import React, { FC, memo } from 'react';
import { VStack } from '@chakra-ui/react';
import { ProductOrderedDetailsText } from './common';
import { useMemo } from 'react';
import { createSubItemString } from './common/functions';

type T = {
  subItems: ISubItemGroup[];
  isTruncated: boolean;
};

type TItem = {
  item?: IMenuItem;
  truncatedString?: string;
};

const Item: FC<TItem> = ({ item, truncatedString }) => {
  const text = useMemo(() => truncatedString || createSubItemString(item), [item, truncatedString]);

  return (
    <ProductOrderedDetailsText
      noOfLines={truncatedString ? 1 : undefined}
      data-testid="ordered-item-sub-item"
    >
      {text}
    </ProductOrderedDetailsText>
  );
};

const ProductOrderedSubItemsInfoComponent: FC<T> = ({ subItems, isTruncated }) => {
  let truncatedString = '';
  subItems.forEach((sIG) =>
    sIG?.items.forEach((item) => (truncatedString += createSubItemString(item, true)))
  );

  return (
    <VStack spacing={0} align="flex-start" justify="center" id="product-sub-item">
      {isTruncated ? (
        <Item truncatedString={truncatedString} />
      ) : (
        subItems?.map((sIG) => sIG?.items?.map((item) => <Item item={item} key={item.id} />))
      )}
    </VStack>
  );
};

export const ProductOrderedSubItemsInfo = memo(ProductOrderedSubItemsInfoComponent);
