import React, { memo, FC } from 'react';
import { VStack } from '@chakra-ui/react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { AnimatePresence } from 'framer-motion';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { OrderItems } from './ProductOrderInfo/OrderItems';
import { MultiGuestItems } from './ProductOrderInfo/MultiGuestItems';
import { filterSentOrUnsentItems } from '@olo-web/utils/common/functions/filterSentOrUnsentItems';
type T = {
  showActionButtons?: boolean;
  isUnsentItemsOnly?: boolean;
  isSentItemsOnly?: boolean;
  itemsToDisplay?: IOrderItem[];
  guestsWithFilteredItems?: IGuest[];
};

export const gridTemplate = {
  base: 'repeat(8, 1fr)',
  xs: 'repeat(12, 1fr)',
  sm: 'repeat(16, 1fr)',
  md: 'repeat(8, 1fr)',
};

const ProductOrderedInfoComponent: FC<T> = ({
  showActionButtons = false,
  isUnsentItemsOnly,
  isSentItemsOnly,
  guestsWithFilteredItems,
  itemsToDisplay,
}) => {
  const { data: order } = useOrder();
  const { data: flags } = useFeatureFlags();
  const savedDineInState = useSavedDineInContextState();
  const { guests, items } = order || {};
  const guestsOrFilteredGuests = guestsWithFilteredItems || guests || [];
  const sortedGuests = guestsOrFilteredGuests?.sort((g) =>
    g.id === savedDineInState?.guest?.id ? 1 : -1
  );
  const displayItems =
    itemsToDisplay || filterSentOrUnsentItems(items, isUnsentItemsOnly, isSentItemsOnly);

  return (
    <VStack
      justifyContent="space-between"
      alignItems="stretch"
      spacing={showActionButtons ? 2 : 4}
      w="100%"
      data-testid="all-ordered-items"
    >
      <AnimatePresence initial={false}>
        {guestsOrFilteredGuests?.length > 1 || order?.tableItems?.length ? (
          <MultiGuestItems
            currentGuest={savedDineInState?.guest}
            guests={sortedGuests}
            flags={flags}
            tableItems={order?.tableItems}
            showActionButtons={showActionButtons}
            isUnsentItemsOnly={isUnsentItemsOnly}
            isSentItemsOnly={isSentItemsOnly}
          />
        ) : (
          <OrderItems items={displayItems} flags={flags} showActionButtons={showActionButtons} />
        )}
      </AnimatePresence>
    </VStack>
  );
};

export const ProductOrderedInfo = memo(ProductOrderedInfoComponent);
