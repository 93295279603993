import React, { useState, useEffect } from 'react';
import { HStack, VStack, Button } from '@chakra-ui/react';
import { ProductOrderedModifiersInfo } from './ProductOrderedModifiersInfo.ui';
import { ProductOrderedSubItemsInfo } from './ProductOrderedSubItemsInfo.ui';

export const ProductOrderedItemDetails = ({ orderItem }) => {
  const { modifiers, subItems } = orderItem;
  const [isTruncated, setIsTruncated] = useState(true);

  const showTruncateButton =
    modifiers.length + subItems.length >= 2 ||
    (subItems?.length && subItems.some((sIG) => sIG?.items.length >= 2));

  // if user edits item, removes or adds modifiers or subItems,
  // truncate buttton should dis/appear so do truncation state
  useEffect(() => setIsTruncated(showTruncateButton), [showTruncateButton]);

  const showSubItem =
    (isTruncated && !modifiers.length && subItems.length) ||
    (!isTruncated && (subItems?.length || subItems.some((sIG) => sIG?.items.length)));

  const handleTruncate = () => {
    setIsTruncated((state) => !state);
  };

  return (
    <HStack align="end" justify="space-between">
      <VStack align="start" spacing={0}>
        {modifiers.length && (
          <ProductOrderedModifiersInfo
            modifiers={isTruncated ? [modifiers[0]] : modifiers}
            isTruncated={isTruncated}
          />
        )}
        {showSubItem && (
          <ProductOrderedSubItemsInfo subItems={subItems} isTruncated={isTruncated} />
        )}
      </VStack>
      {showTruncateButton && (
        <Button
          color="blackAlpha.400"
          size="s"
          textStyle="label"
          onClick={handleTruncate}
          m={1}
          variant="link"
          textDecor="underline"
          fontWeight="400"
        >
          {isTruncated ? 'Show more' : 'Show less'}
        </Button>
      )}
    </HStack>
  );
};
